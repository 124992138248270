export default `
### Beskriver hushållets sannolika köpkraft.
Ett mått på hur mycket pengar som finns kvar i plånboken efter fasta utgifter. 
Köpkraft baseras på hushållets totala inkomster (och från den dras skatt och utgifter kopplade till boende, barn, livsmedel och eventuell bil.
* Mycket hög 
* Hög  
* Medelhög 
* Medellåg
* Låg 
* Mycket låg
`